import Vue from "vue";
import Vuex from "vuex";
import {
  SET_IS_LOADING_MUTATION,
  SET_AUTH_MUTATION,
  SET_AUTH_LOGOUT_MUTATION,
  SET_USER_MUTATION,
  GET_USER,
  SET_REFERRAL,
  GET_REFERRAL,
  GET_AUTH,
} from "./constants.js";

Vue.use(Vuex);

export * from './constants.js'

export default new Vuex.Store({
  state: {
    isLoading: false,
    userDetails: {},
    auth: false,
    referralCode: null,
    swDetailsFilled: false
  },
  mutations: {
    [SET_IS_LOADING_MUTATION](state, payload) {
      state.isLoading = payload;
    },
    [SET_AUTH_LOGOUT_MUTATION](state, payload) {
      state.auth = payload;
      localStorage.clear();
      document.cookie =
        "@uncovor::login=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      location.assign("/");
    },
    [SET_AUTH_MUTATION](state, payload) {
      state.auth = payload;
    },
    [SET_USER_MUTATION](state, payload) {
      state.userDetails = payload;
      if (payload.education?.length && payload.work_ex?.length && payload.salary?.length) {
        state.swDetailsFilled = true;
      }
    },
    [SET_REFERRAL](state, payload) {
      state.referralCode = payload;
    },
  },
  getters: {
    [GET_USER](state) {
      return state.userDetails;
    },
    [GET_REFERRAL](state) {
      return state.referralCode;
    },
    [GET_AUTH](state) {
      return state.auth;
    },
  },
  modules: {},
});
