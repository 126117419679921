<template>
  <div id="_app">
    <div v-if="isLoaderVisible" class="main-app-loader-wrapper">
      <AppLoader />
    </div>

    <router-view />
  </div>
</template>
<script>
// import { api } from "./services/http-common.js";
import AppLoader from "./components/loader.vue";
import { subscribeEvent } from "./helpers/Observer";
import { KEYWORDS } from "./helpers/constants";

export default {
  components: {
    AppLoader,
  },
  data() {
    return {
      isLoaderVisible: false,
    };
  },
  methods: {
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    showLoader() {
      this.isLoaderVisible = true;
      document.querySelector("body").classList.add("noScroll");
      document.querySelector("html").classList.add("noScroll");
    },
    hideLoader() {
      this.isLoaderVisible = false;
      document.querySelector("body").classList.remove("noScroll");
      document.querySelector("html").classList.remove("noScroll");
    },
  },
  created() {
    /**
     * Moved this to main.js
     */
    // if (this.getCookie("@uncovor::login")) {
    //   this.showLoader();
    //   api
    //     .get(`api/v1/user/profile`)
    //     .then((response) => {
    //       // console.log(response.data);
    //       this.$store.commit("setUser", response.data);
    //       //TODO : set the api call and login state using a preloader
    //       this.$store.commit("setAuth", true);
    //       this.hideLoader();
    //     })
    //     .catch((e) => {
    //       this.hideLoader();
    //       // TODO: what to do here? /
    //       console.log(e);
    //     });
    // }
    subscribeEvent(KEYWORDS.SHOW_LOADER, this.showLoader);
    subscribeEvent(KEYWORDS.HIDE_LOADER, this.hideLoader);
  },
  mounted() {
    const queryString = window.location.hash.replace("#/", "");
    const urlParams = new URLSearchParams(queryString);
    let referralCode = urlParams.get("ref");
    let existingReferral = this.getCookie("@uncovor::ref");

    if (referralCode !== null) {
      let date = new Date(Date.now() + 86400e3);
      date = date.toUTCString();
      document.cookie = `@uncovor::ref=${referralCode}; expires=${date} path=/`;
      this.$store.commit("setReferral", referralCode);
    } else if (existingReferral !== null) {
      this.$store.commit("setReferral", existingReferral);
    }
  },
};
</script>

<style lang="scss">
#_app {
  // cursor: url("./assets/images/cursors/cursor.svg"), auto;
  // button,
  // input,
  // a {
  //   &:hover {
  //     cursor: url("./assets/images/cursors/hover.svg"), auto;
  //   }
  //   &:disabled {
  //     cursor: url("./assets/images/cursors/disabled.svg"), auto;
  //   }
  // }
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  .main-app-loader-wrapper {
    width: 100vw;
    height: 100vh;
    position: absolute;
  }
}
</style>
