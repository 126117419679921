import axios from "axios";

export const api = axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: {
    accept: "application/json",
    platform: "web",
  },
  withCredentials: true,
});

let accessTokenRetry = false;

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    // console.log(error, error.response)

    if (error.response.status === 401 && !accessTokenRetry) {
      accessTokenRetry = true;
      api.get(`api/v1/token/access`).then(() => {
        return api(originalRequest);
      });
    } else if (error.response.status === 401 && accessTokenRetry) {
      accessTokenRetry = false;
    }
    return Promise.reject(error);
  }
);
