import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "home_view" */ "../views/Home/index.vue"),
  },
  {
    path: "/waitlist",
    name: "Waitlist",
    component: () =>
      import(/* webpackChunkName: "home_view" */ "../views/Waitlist.vue"),
    meta: { requiresLogin: true },
  },
  {
    path: "/know-your-self-worth",
    name: "SelfWorth",
    component: () =>
      import(
        /* webpackChunkName: "home_view" */ "../views/Self-Worth/index.vue"
      ),
    meta: { requiresLogin: true },
  },
  {
    path: "/know-your-self-worth/insights",
    name: "SelfWorthInsights",
    component: () =>
      import(
        /* webpackChunkName: "home_view" */ "../views/Self-Worth/insights.vue"
      ),
    meta: { requiresLogin: true },
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "home_view" */ "../views/About.vue"),
  },
  {
    path: "/terms-conditions",
    name: "termsConditions",
    component: () =>
      import(/* webpackChunkName: "home_view" */ "../views/Terms-Conditions.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "home_view" */ "../views/Profile/index.vue"),
    meta: { requiresLogin: true },
  },
  { path: '*', redirect: '/' }
];

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior: function (to) {
    if (to.hash) {
      return { selector: to.hash };
      //Or for Vue 3:
      //return {el: to.hash}
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  setTimeout(() => {
    if (
      to.name == "SelfWorth" &&
      (store.state.userDetails.salary_update_allowance == 1 || store.state.swDetailsFilled)
    ) {
      next({ name: "SelfWorthInsights" });
      return;
    }

    if (to.matched.some((record) => record.meta.requiresLogin)) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      if (!store.state.auth) {
        next({ name: "Home" });
      } else {
        next(); // go to wherever I'm going
      }
    } else {
      next();
    }
  }, 200);
});

export default router;
