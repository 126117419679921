<template>
  <div class="app-loader">
    <div class="app-loader-backdrop"></div>
    <div class="lottie-wrapper">
      <lottie :options="data" />
    </div>
  </div>
</template>

<script>
import loaderLottieJson from "@/assets/lotties/app-loader-lottie.json";
export default {
  data() {
    return {
      data: { animationData: loaderLottieJson },
    };
  },
};
</script>

<style lang="scss" scoped>
.app-loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  .app-loader-backdrop {
    background: #000000b0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: inherit;
  }
  .lottie-wrapper {
    width: 100px;
    height: 100px;
    background: white;
    border-radius: 18px;
    z-index: inherit;
  }
}
</style>
