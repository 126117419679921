import Vue from "vue";
import Lottie from "vue-lottie";
Vue.component("lottie", Lottie);

import App from "./App.vue";
import router from "./router";
import store, {SET_AUTH_LOGOUT_MUTATION} from "./store";
import Particles from "particles.vue";
import "./assets/scss/main.scss";
// import i18n from "./i18n";
import { registerApp } from "./helpers/Observer";
import Buefy from "buefy";
import "buefy/dist/buefy.css";
// import * as Sentry from "@sentry/vue";
// import { BrowserTracing } from "@sentry/tracing";
import { getCookie } from "./helpers/utils";
import { api } from "./services/http-common";

Vue.config.productionTip = false;
Vue.use(Particles);
Vue.use(Buefy);

// Sentry.init({
//   Vue,
//   dsn: "https://4b5375a8f81f4e8e876c0fb2e1bb523d@o1172942.ingest.sentry.io/6267852",
//   integrations: [
//     new BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: ["localhost", "https://uncovor-20756.web.app/", /^\//],
//     }),
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

if (getCookie("@uncovor::login")) {
  api
    .get(`api/v1/user/profile`)
    .then((response) => {
      store.commit("setUser", response.data);
      store.commit("setAuth", true);
    })
    .catch((e) => {
      console.log(e)
      store?.commit(SET_AUTH_LOGOUT_MUTATION, true);
    })
    .finally(() => {
      renderApp();
    });
} else {
  renderApp();
}

function renderApp() {
  let appInstance = new Vue({
    router,
    store,
    // i18n: i18n.i18n,
    render: (h) => h(App),
  });
  registerApp(appInstance);
  appInstance.$mount("#app");
}
