/* eslint-disable */
/**
 * getCookie
 * @param {string} cname
 * @returns string - Cookies value
 */
export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

/**
 * debounce helper
 * @param {function} fn
 * @param {number} delay - milliseconds
 * @returns function
 */
export function debounce(fn, delay = 500) {
  var timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    var args = arguments;
    var that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}

/**
 * Remove falsy values from nested object
 * @param {object} object
 * @returns object
 */
export function cleanObject(object) {
  Object.entries(object).forEach(([k, v]) => {
    if (v && typeof v === "object") {
      cleanObject(v);
    }
    if (v && typeof v === "object" && !Object.keys(v).length || v === null || v === undefined || v.length === 0) {
      if (Array.isArray(object)) {
        object.splice(k, 1);
      } else if (!(v instanceof Date)) {
        delete object[k];
      }
    }
  });
  return object;
}

/**
 * Convert numbers to words (asian numbering system)
 * @param {*} num
 * @returns {string}
 */
export function inWords(formattedNumericValue) {
  let num = +formattedNumericValue.toString().replace(/\D/gi,'');
  let a = ["","one ","two ","three ","four ", "five ","six ","seven ","eight ","nine ","ten ","eleven ","twelve ","thirteen ","fourteen ","fifteen ","sixteen ","seventeen ","eighteen ","nineteen "];
  let b = ["", "", "twenty","thirty","forty","fifty", "sixty","seventy","eighty","ninety"];
  if ((num = num.toString()).length > 9) return "";
  let n = ("000000000" + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return; let str = "";
  str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore " : "";
  str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh " : "";
  str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand " : "";
  str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred " : "";
  str += (n[5] != 0) ? ((str != "") ? "and " : "") + (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) + "" : "";
  return str;
}

/**
 * Convert numbers to words Millions
 * @param {*} num
 * @returns {string}
 */
export function inMillions(formattedNumericValue) {
  let num = +formattedNumericValue.toString().replace(/\D/gi,'');
  // Nine Zeroes for Billions
  return Math.abs(Number(num)) >= 1.0e+9
  ? (Math.abs(Number(num)) / 1.0e+9).toFixed(2) + " Billion Dollars"
  // Six Zeroes for Millions 
  : Math.abs(Number(num)) >= 1.0e+6
  ? (Math.abs(Number(num)) / 1.0e+6).toFixed(2) + " Million Dollars"
  // Three Zeroes for Thousands
  : Math.abs(Number(num)) >= 1.0e+3
  ? (Math.abs(Number(num)) / 1.0e+3).toFixed(2) + " Thousand Dollars"
  : Math.abs(Number(num));
}

/**
 * Get closest index from an array
 * @param {*} num 
 * @param {*} arr 
 * @returns num
 */
export function closestIndex(num, arr) {
  let curr = arr[0], diff = Math.abs(num - curr);
  let index = 0;
  for (let val = 0; val < arr.length; val++) {
      let newdiff = Math.abs(num - arr[val]);
      if (newdiff < diff) {
        diff = newdiff;
        curr = arr[val];
        index = val;
      };
  };
  return index;
}

/**
 * Convert locale number string to number
 * @param {*} num
 * @returns {string}
 */
export function toNumber(formattedNumericValue) {
  let num = +formattedNumericValue?.toString().replace(/\D/gi,'');
  return num || 0
}


/**
 * Convert years in floating number to years and months object
 * @param {*} val
 * @returns object {years: number, months: number}
 */
export const getYearsAndMonthsFromYears = (val = 0) => {
  const remainder = val % 1 || 0
  const years = val - remainder || 0
  const months = Math.round(remainder * 12)
  return {years, months}
}

/**
 * Convert months in numbers to years and months object
 * @param {*} val
 * @returns object {years: number, months: number}
 */
export const getYearsAndMonthsFromMonths = (val = 0) => {
  const months = val % 12
  const years = Math.floor(val / 12)
  return {years, months}
}